/* eslint-disable import/no-unused-modules */
import React, { Fragment } from 'react';
import styled from 'styled-components';
import Grid from '@mui/material/Grid';
import { graphql } from 'gatsby';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import Helmet from '../components/Helmet';
import Lottie from '../components/Lottie';
import { H1, H2, P, PMedium } from '../components/Text';
import { PageLayout } from '../shared/layouts';
import withI18n from '../hocs/withI18n';
import {
  securityCards,
  securityProductContents,
  securityNetworkContents,
} from '../modules/security/Security.helper';
import { FONT_BOLD, SCREEN_PAD } from '../shared/styles';

export const Head = withI18n(() => {
  return <Helmet pageName="security" />;
});

const Security = () => {
  const { t } = useTranslation();

  return (
    <PageLayout blockPadding>
      <PageInnerLayout>
        <span className="muted p-sm">{t('policy.label')}</span>
        <H1 margin="6px 0 1rem 0">{t('security-policy.title')}</H1>
        <P align="start">{t('security-policy.sub-title')}</P>
        <Grid container justifyContent="center" spacing={4}>
          {securityCards.map((value, key) => (
            <Grid key={key} item xs={12} md={4}>
              <SecurityCard>
                <Lottie src={value.animation} style={lottieStyle} />
                <P className="card-title">{t(value.title)}</P>
                <P align="start" margin="0">
                  {t(value.contents)}
                </P>
              </SecurityCard>
            </Grid>
          ))}
        </Grid>
      </PageInnerLayout>
      <SecurityTexts bg="#fff9f7">
        <H2 align="start" margin="0 0 2rem 0">
          {t('security-policy.features.title')}
        </H2>
        {securityProductContents.map((item, index) => (
          <Fragment key={index}>
            <PMedium>{t(item.title)}</PMedium>
            <P align="start">{t(item.content)}</P>
          </Fragment>
        ))}
      </SecurityTexts>

      <SecurityTexts className="pb-0">
        <H2 align="start" margin="0 0 2rem 0">
          {t('security-policy.frameworks.title')}
        </H2>
        {securityNetworkContents.map((item, index) => (
          <Fragment key={index}>
            <PMedium>{t(item.title)}</PMedium>
            <P align="start">{t(item.content)}</P>
          </Fragment>
        ))}
      </SecurityTexts>
    </PageLayout>
  );
};

export default Security;
export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
const PageInnerLayout = styled.div`
  ${SCREEN_PAD}
  padding-bottom: 7rem;
`;
const SecurityTexts = styled.div`
  background: ${(props) => props.bg || '#FFF'};
  padding-bottom: 4rem;
  ${SCREEN_PAD}
  padding-top: 4rem;
  &.pb-0 {
    padding-bottom: 0;
  }
`;
const SecurityCard = styled.div`
  border: 1px solid #e7e7e7;
  border-radius: 10px;
  height: 100%;
  padding: 10px 20px;
  .card-title {
    margin: 20px 0 10px 0;
    ${FONT_BOLD};
    text-align: start;
  }
`;

const lottieStyle = {
  boxShadow: '0px 0px 13px -6px #928e8e',
  borderRadius: '50%',
  width: '60px',
  height: '60px',
  margin: '7px 0 0 0',
};
