import securityOneAnimation from '../../../static/json/security-1.json';
import securityTwoAnimation from '../../../static/json/security-2.json';
import securityThreeAnimation from '../../../static/json/security-3.json';

export const securityCards = [
  {
    title: 'security-policy.cards.identity-title',
    contents: 'security-policy.cards.identity-content',
    animation: securityOneAnimation,
  },
  {
    title: 'security-policy.cards.data-protection-title',
    contents: 'security-policy.cards.data-protection-content',
    animation: securityTwoAnimation,
  },
  {
    title: 'security-policy.cards.transparency-title',
    contents: 'security-policy.cards.transparency-content',
    animation: securityThreeAnimation,
  },
];

export const securityProductContents = [
  {
    title: 'security-policy.features.sso.title',
    content: 'security-policy.features.sso.content',
  },
  {
    title: 'security-policy.features.permissions.title',
    content: 'security-policy.features.permissions.content',
  },
  {
    title: 'security-policy.features.credentials.title',
    content: 'security-policy.features.credentials.content',
  },
  {
    title: 'security-policy.features.uptime.title',
    content: 'security-policy.features.uptime.content',
  },
  {
    title: 'security-policy.features.customer-support.title',
    content: 'security-policy.features.customer-support.content',
  },
];

export const securityNetworkContents = [
  {
    title: 'security-policy.frameworks.hosting.title',
    content: 'security-policy.frameworks.hosting.content',
  },
  {
    title: 'security-policy.frameworks.failover.title',
    content: 'security-policy.frameworks.failover.content',
  },
  {
    title: 'security-policy.frameworks.backups.title',
    content: 'security-policy.frameworks.backups.content',
  },
  {
    title: 'security-policy.frameworks.cloud.title',
    content: 'security-policy.frameworks.cloud.content',
  },
  {
    title: 'security-policy.frameworks.permissions.title',
    content: 'security-policy.frameworks.permissions.content',
  },
  {
    title: 'security-policy.frameworks.encryption.title',
    content: 'security-policy.frameworks.encryption.content',
  },
];
